export const checkIfInteger = (number) => {
  if (number % 1 !== 0) {
    return false;
  }
  return true;
};

export const calculateDiscountAmount = (type, amount, price) => {
  let discount = 0;
  let discountedPrice = 0;

  if (type === "percentage") {
    discount = `${amount}%`;
    discountedPrice = price - price * (Number(amount) / 100);

    discountedPrice = Math.max(discountedPrice, 0);

    if (!checkIfInteger(discountedPrice)) {
      discountedPrice = discountedPrice.toFixed(2);
    }
  }

  if (type === "flat") {
    discount = `${amount}`;
    discountedPrice = price - Number(amount);

    discountedPrice = Math.max(discountedPrice, 0);

    if (!checkIfInteger(discountedPrice)) {
      discountedPrice = discountedPrice.toFixed(2);
    }
  }

  return {
    discount,
    discountedPrice,
  };
};
