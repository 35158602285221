import axios from "axios";

export const proxyUrl =
process.env.VUE_APP_APP_IDENTIFIER
  ? process.env.VUE_APP_APP_IDENTIFIER + "/dev"
  : "product-subscription";

export const getPlans = async (product_id) => {
  return axios.get(`/ext/${proxyUrl}/v1/product-plans?product_id=${product_id}`);
};

export const addCartId = async (data) => {
  return axios.patch(`/ext/${proxyUrl}/v1/product-plans`, { ...data });
};
