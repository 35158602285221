import App from "./App.vue";

// Vue.config.productionTip = false;

// new Vue({
//   render: (h) => h(App),
// }).$mount("#app");

window.FPI.extension.register("#product-subscription", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: App,
    });
  },
});
