<template>
  <fdk-pincode>
    <template slot-scope="pincode">
      <fdk-accounts>
        <template slot-scope="accountsData">
          <div v-if="plans.length">
            <button type="button" class="subscribe-button" @click="handleSubscribeClick(pincode, accountsData)"
              aria-label="Subscribe Button">
              {{ getButtonText(accountsData) }}
            </button>
            <div class="error" v-if="error.length > 0">{{ error }}</div>

            <PlanModal v-show="isModalVisible" @close="closeModal" :plans="plans" :location="location" :product="product"
              :selectedArticle="selectedArticle" :getUserEmail="getUserEmail" :addEmail="accountsData.addEmail" />
          </div>
        </template>
      </fdk-accounts>
    </template>
  </fdk-pincode>
</template>

<script>
import PlanModal from "../plan-modal/plan-modal.component.vue";
import { getPlans } from "../../services/plans.service.ts";

export default {
  name: "SubscribeButton",
  components: {
    PlanModal,
  },
  data() {
    return {
      isModalVisible: false,
      error: "",
      product: {},
      location: {},
      user: {},
      productEvent: {}, //all available sizes -- not working
      selectedSize: "", // ??
      sellable: "", // ??
      plans: [],
      selectedArticle: {},
      getUserEmail: false, // to get user email
    };
  },
  mounted() {
    window.FPI.event.on("product.view", function (eventData) {
      //getting size option**
      this.productEvent = eventData; // not working
    });
    window.FPI.state.product.subscribe(({ product }) => {
      this.product = product;
    });
    window.FPI.state.user.subscribe((user) => {
      this.user = user;
    });
    window.FPI.state.global.location.subscribe((location) => {
      //pincode
      this.location = location;
    });
  },
  watch: {
    product: function (newVal) {
      this.getProductPlans(newVal);
    },
  },

  methods: {
    getButtonText(accountsData) {
      if (!accountsData.is_logged_in) {
        return "Login to Subscribe";
      } else {
        return "Subscribe And Save";
      }
    },
    async handleSubscribeClick(pincode, accountsData) {
      /** Any logic if required before the modal opens goes here */

      if (!accountsData.is_logged_in) {
        accountsData.openLogin();
        return;
      }

      const userEmail =
        accountsData?.user_data?.user?.emails?.find(
          (userEmail) => userEmail.primary === true
        )?.email ?? "";

      if (!userEmail) {
        this.getUserEmail = true;
      }

      if (!window.FPI?.event?.state?.product?.meta?._data?.sellable) {
        this.error = "Product not available";
        return;
      }

      if (window?.FPI?.event?.state?.product?.meta?._data?.sizes?.length > 0) {
        if (!window?.FPI?.event?.state?.product?.selectedArticle?._data?.size) {
          this.error = "Please select size of product";
          return;
        }
      }

      // if (
      //   !window?.FPI?.event?.state?.product?.selectedArticle?._data
      //     ?.is_serviceable
      // ) {
      //   this.error = "Product not serviceable in your area";
      //   return;
      // }

      // const isValidPincode = await this.validatePincode(pincode);

      // if (!isValidPincode) {
      //   this.error = "Product not serviceable in your area";
      //   return;
      // }

      this.selectedArticle =
        window?.FPI?.event?.state?.product?.selectedArticle;

      this.isModalVisible = true;
    },

    async getProductPlans(product) {
      try {
        const res = await getPlans(product.uid);

        if (res?.data?.data) {
          const activePlans =
            res?.data?.data?.[0]?.docs?.filter((plan) => plan.is_active) ?? [];

          const formattedPlans =
            activePlans?.map((plan) => {
              const type = plan?.promotion;

              return {
                id: plan?._id,
                value: plan?.frequency,
                text:
                  plan?.frequency?.charAt(0).toUpperCase() +
                  plan?.frequency?.slice(1),
                type: type,
                discountValue: plan?.promotion_value,
              };
            }) ?? [];
          this.plans = formattedPlans;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async validatePincode(pincode) {
      try {
        const isValidPincode = await pincode.validatePincode(
          this.location.pincode
        );

        return isValidPincode ?? false;
      } catch (err) {
        console.error(err);
      }

      return false;
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
.subscribe-button {
  display: flex;
  height: 56px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: var(--buttonPrimary, #4e3f09);
  width: 100%;
  border: none;
  outline: none;

  color: var(--buttons-secondary, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: uppercase;
  cursor: pointer;
}

.subscribe-button:hover {
  border-color: var(--buttonPrimaryL1, #816e2a);
  background-color: var(--buttonPrimaryL1, #816e2a);
}

.error {
  color: var(--errorText, red);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: uppercase;
}
</style>
