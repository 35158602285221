<template>
  <fdk-cart class="product__actions">
    <template slot-scope="cart">
      <div class="modal-backdrop">
        <div class="modal" role="dialog" aria-label="subscription plan modal">
          <header class="modal-header">
            <div>Subscribe and Save</div>
            <button type="button" class="btn-close" @click="close">
              <img alt="close-icon" :src="closeIcon" />
            </button>
          </header>

          <section class="modal-body">
            <div class="radio-group">
              <label
                v-for="option in updatedPlans"
                :key="option.id"
                class="radio-element"
              >
                <input
                  type="radio"
                  :value="option.id"
                  v-model="selectedOption"
                />
                <div class="radio-label">
                  <span class="radio-text">{{ option.text }}</span>
                  <span v-if="option.discountedPrice" class="discounted-amount">
                    &#8377; {{ option.discountedPrice }}
                  </span>
                  <span
                    v-if="option.discountedPrice && option.price"
                    class="actual-price"
                  >
                    MRP: &#8377; {{ option.price }}
                  </span>
                  <span v-if="option.discount" class="discount-percentage-tag">
                    Save {{ option.discount }}
                  </span>
                </div>
              </label>
            </div>

            <div class="email-input-group" v-if="getUserEmail">
              <label for="email" class="email-label">Enter your email:</label>
              <input
                for="email"
                type="email"
                placeholder="Please enter your email here.."
                v-model="email"
                class="email-input"
              />
            </div>
          </section>

          <footer class="modal-footer">
            <button
              type="button"
              :class="{
                'disabled-button': getUserEmail && email.length === 0,
                'proceed-button': true,
              }"
              @click="handleProceedClick(cart)"
              aria-label="Select & Proceed Button"
              :disabled="getUserEmail && email.length === 0"
            >
              Select & Proceed
            </button>
          </footer>
        </div>
      </div>
    </template>
  </fdk-cart>
</template>

<script>
import CloseIcon from "../../assets/close-icon.svg";
import { addCartId } from "../../services/plans.service.ts";
import { calculateDiscountAmount } from "../../common/utils.js";

export default {
  name: "PlanModal",

  props: {
    plans: [],
    location: [],
    product: {},
    selectedArticle: {},
    getUserEmail: {
      type: Boolean,
      default: false,
    },
    addEmail: {},
  },

  data: function () {
    return {
      closeIcon: CloseIcon,
      selectedOption: "",
      user: {},
      updatedPlans: [],
      copyPlans: [],
      email: "",
    };
  },

  watch: {
    selectedArticle(newValue) {
      this.updatePlans(newValue);
    },
  },

  mounted() {
    window.FPI.state.user.subscribe((user) => {
      this.user = user;
    });
  },

  methods: {
    close() {
      this.$emit("close");
    },

    updatePlans(selectedArticle) {
      if (!selectedArticle) {
        return;
      }

      const selectedProductPrice = selectedArticle?._data?.price?.effective;

      this.updatedPlans = this.plans?.map((plan) => {
        const { type, discountValue } = plan;

        const { discount, discountedPrice } = calculateDiscountAmount(
          type,
          discountValue,
          selectedProductPrice
        );

        this.copyPlans.push({ price: discountedPrice, id: plan.id });

        return {
          ...plan,
          discount,
          discountedPrice,
          price: selectedProductPrice,
        };
      });

      this.copyPlans.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

      if (this.copyPlans?.[0]?.id) {
        this.setSelectedOption(this.copyPlans?.[0]?.id);
      }
    },

    async handleProceedClick(cart) {
      /** Add code for handling the proceed button click  */

      if (this.getUserEmail && !this.email) {
        return;
      }

      if (this.getUserEmail) {
        await this.addEmail({ email: this.email });
      }

      if (!this.selectedOption) {
        return;
      }

      let addItemData = {
        items: [
          {
            item_id: this.product.uid,
            item_size:
              window?.FPI?.event?.state?.product?.selectedArticle?._data?.size,
            quantity: 1,
            seller_id:
              window?.FPI?.event?.state?.product?.selectedArticle?._data?.seller
                ?.uid,
            store_id:
              window?.FPI?.event?.state?.product?.selectedArticle?._data?.store
                ?.uid,
          },
        ],
        buy_now: true,
      };

      cart
        .addToCart(addItemData)
        .then(async (data) => {
          if (data.success) {
            try {
              await addCartId({
                cart_id: data?.cart?.id,
                user_id: this.user?.user?._id,
                product_id: String(this.product?.uid),
                product_plan_id: this.selectedOption,
              });
            } catch (err) {
              console.error(err);
            }
            this.$router.push(
              `/cart/checkout?id=${data?.cart?.id}&buy_now=true`
            );
          } else {
            console.log("Add to Cart Failed ", data);
          }
        })
        .catch((err) => {
          console.error("error", err);
        });
    },

    setSelectedOption(selectedOption) {
      this.selectedOption = selectedOption;
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(51, 51, 51, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow-x: auto;

  border-radius: 12px;
  background: var(--backgrounds-white, #fff);
  z-index: 10000;
}

.modal-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 1px solid #e4e5e6;
  align-items: center;
  width: -webkit-fill-available;

  color: var(--text-color-primary-and-secondary, #41434c);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.btn-close {
  border: none;
  cursor: pointer;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body {
  position: relative;
  margin: 32px 32px 0;

  width: -webkit-fill-available;
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radio-element {
  display: flex;
  align-items: center;

  gap: 6px;
}

.radio-element input {
  margin: 0;
  accent-color: black;
}

.radio-label {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.discounted-amount {
  color: var(--text-headings, #261a1a);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  text-transform: capitalize;
}

.actual-price {
  color: var(--text-labels-placeholders, #7d7676);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  text-decoration: line-through;
}

.discount-percentage-tag {
  display: inline-flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: #000;

  color: var(--semantic-error-message, #fff5f5);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.2px;
}

.radio-text {
  color: var(--text-color-primary-and-secondary, #41434c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.modal-footer {
  flex-direction: column;
  justify-content: flex-end;
  margin: 32px;
  width: -webkit-fill-available;
}

.proceed-button {
  display: flex;
  height: 56px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: var(--buttonPrimary, #4e3f09);
  width: 100%;
  border: none;
  outline: none;

  color: var(--buttons-secondary, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: uppercase;
  cursor: pointer;
}

.email-input-group {
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  gap: 4px;
}

.email-label {
  color: var(--text-color-primary-and-secondary, #41434c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.email-input {
  padding: 4px;
}

.disabled-button {
  opacity: 0.4;
  cursor: not-allowed;
}
</style>
