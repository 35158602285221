<template>
  <div id="subscription-wrapper">
    <SubscribeButton />
  </div>
</template>

<script>
import SubscribeButton from "./components/subscribe-button/subscribe-button.component.vue";

export default {
  name: "App",
  components: {
    SubscribeButton,
  },
};
</script>

<style></style>
